import {
  DataSettingsKeyType,
  FormattedData,
  OrgDataSettingsConfig,
} from 'app/modules/dataSettings/responses';
import { FieldInfo } from 'app/modules/watchlists/models';

// Get watchlist name
export const getWatchlistResultName = (
  watchlistConfig: OrgDataSettingsConfig[][] | undefined,
  formattedData: FormattedData | undefined,
): string => {
  let watchlistName = '';
  if (formattedData !== undefined) {
    watchlistConfig?.forEach((group) => {
      group.forEach((data) => {
        if (
          data !== null &&
          (data.user_facing_label?.toLowerCase().includes('name') ||
            (data.key_type === DataSettingsKeyType.CUSTOM &&
              data.key_path?.includes('full_name'))) &&
          formattedData[data.id].formatted_value !== ''
        ) {
          watchlistName = formattedData[data.id].formatted_value;
        }
      });
    });
  }
  return watchlistName;
};

// Resize config arr
export const resizeConfigArr = (
  config: OrgDataSettingsConfig[],
  matchingConfig: OrgDataSettingsConfig[],
): OrgDataSettingsConfig[] => {
  const maxSize = Math.max(config.length, matchingConfig.length);
  const arr = Array(maxSize).fill(null);
  return arr.map((x, i) => {
    return config[i] ?? null;
  });
};

// This function is need to ensure that the UI is rerendered
export const deepShallowCopyFieldInfo = (fieldInfos: FieldInfo): FieldInfo => ({
  fields: [...fieldInfos.fields],
  entityUnusedFields: [...fieldInfos.entityUnusedFields],
  watchlistUnusedFields: [...fieldInfos.watchlistUnusedFields],
  formattedData: { ...fieldInfos.formattedData },
});
