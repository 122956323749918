import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// Reducers
import { commentsReducer } from 'app/modules/comments/reducer';
import { notesReducer } from 'app/modules/notes/reducer';
import { immerRulesReducer } from 'app/modules/rules/reducer';
import { sarsReducer } from 'app/modules/fincenSar/reducer';
import { searchReducer } from 'app/modules/search/reducer';
import { webhooksReducer } from 'app/modules/webhooks/reducer';
import { workflowsReducer } from 'app/modules/workflows/reducer';
import { insightsReducer } from 'app/modules/insights/reducer';
import { auditTrailReducer } from 'app/shared/auditTrail/reducer';
import { eventFiltersReducer } from 'app/shared/events/reducer';
import { blacklistsReducer } from 'app/modules/blacklists/reducer';
import { agentsReducer } from 'app/modules/agentsOld/reducer';
import { alertsReducer } from 'app/modules/alerts/reducer';
import devtoolsReducer, {
  DEVTOOLS_SLICE_NAME,
} from 'app/modules/devtools/sliceDevtools';
import { transactionsReducer } from 'app/modules/transactions/reducer';
import { toastsReducer } from 'app/shared/toasts/reducer';
import { uploadsReducer } from 'app/modules/uploads/reducer';
import { teamReducer } from 'app/modules/teamsOld/reducer';
import { dropdownReducer } from 'app/shared/dropdown/reducer';
import { settingsReducer } from 'app/modules/settings/reducer';
import { casesReducer } from 'app/modules/casesOld/reducer';
import { loadingReducer } from 'app/modules/loading/reducer';
import { navigatorReducer } from 'app/modules/navigator/reducer';
import { entitiesReducer } from 'app/modules/entities/reducer';
import { sessionReducer } from 'app/modules/session/reducer';
import { censorshipReducer } from 'app/modules/censorship/reducer';
import { deadlinesReducer } from 'app/modules/deadlines/reducer';
import { customChecklistReducer } from 'app/shared/customChecklist/reducer';
import investigationChecklistReducer, {
  investigationChecklistSliceName,
} from 'app/modules/investigationChecklist/sliceInvestigationChecklist';
import { featureFlagsReducer } from 'app/shared/featureFlags/reducer';
import { filtersReducer } from 'app/modules/filtersOld/reducer';
import { detectionModelsReducer } from 'app/modules/detectionModels/reducer';
import { permissionsReducer } from 'app/modules/permissions/reducer';
import { tableConfigReducer } from 'app/shared/CustomConfig/reducer';
import { goAMLReducer } from 'app/modules/goAML/reducer';
import sidebarReducer, { SIDEBAR_SLICE_NAME } from 'app/modules/sidebar/slice';
import casesSliceReducer, {
  CASES_SLICE_NAME,
} from 'app/modules/cases/sliceCases';
import investigationsReducer, {
  INVESTIGATIONS_SLICE_NAME,
} from 'app/modules/investigations/sliceInvestigations';
import flatFileIngestionReducer, {
  FFIP_NAME,
} from 'app/modules/pullBasedDataFiles/slicePullBasedDataFiles';
import dataMappingReducer, {
  DATA_MAPPING_NAME,
} from 'app/modules/dataMapping/sliceDataMapping';
import notificationsSliceReducer, {
  NOTIFICATIONS_SLICE_NAME,
} from 'app/modules/notifications/sliceNotifications';
import agentsSliceReducer, {
  AGENTS_SLICE_NAME,
} from 'app/modules/agents/sliceAgents';
import impersonationSliceReducer, {
  IMPERSONATION_SLICE_NAME,
} from 'app/modules/impersonation/sliceImpersonation';
import searchSliceReducer, {
  SEARCH_SLICE_NAME,
} from 'app/modules/search/sliceSearch';
import detectionModelsSliceReducer, {
  DETECTION_MODELS_SLICE_NAME,
} from 'app/modules/detectionModels/sliceDetectionModels';
import detectionModelDetailsSliceReducer, {
  DETECTION_MODEL_DETAILS_SLICE_NAME,
} from 'app/modules/detectionModelDetails/sliceDetectionModelDetails';
import relationshipsSliceReducer, {
  RELATIONSHIPS_SLICE_NAME,
} from 'app/modules/relationships/sliceRelationships';
import tagsSliceReducer, { TAGS_SLICE_NAME } from 'app/modules/tags/sliceTags';
import eventsSliceReducer, {
  EVENTS_SLICE_NAME,
} from 'app/shared/events/sliceEvents';
import teamsSliceReducer, {
  TEAMS_SLICE_NAME,
} from 'app/modules/teams/sliceTeams';
import fileExportsRefreshReducer, {
  FILE_EXPORTS_SLICE_NAME,
} from 'app/modules/fileExports/sliceFileExports';
import apiKeysSliceReducer, {
  API_KEYS_SLICE_NAME,
} from 'app/modules/apiKeys/sliceApiKeys';
import orgSettingsSliceReducer, {
  ORG_SETTINGS_SLICE_NAME,
} from 'app/modules/orgSettings/sliceOrgSettings';
import themeSliceReducer, {
  THEME_SLICE_NAME,
} from 'app/modules/theme/sliceTheme';
import dispositionsSliceReducer, {
  DISPOSITIONS_SLICE_NAME,
} from 'app/modules/dispositions/sliceDisposition';
import queuesSliceReducer, {
  QUEUES_SLICE_NAME,
} from 'app/modules/queues/sliceQueues';
import {
  name as dataSettingsName,
  reducer as dataSettingsReducer,
} from 'app/modules/dataSettings/sliceDataSettings';
import {
  name as settingsImportExportName,
  reducer as settingsImportExportReducer,
} from 'app/modules/settingsImportExport/sliceSettingsImportExport';
import {
  name as tableSettingsName,
  reducer as tableSettingsReducer,
} from 'app/modules/tableSettings/sliceTableSettings';
import {
  name as summarySettingsName,
  reducer as summarySettingsReducer,
} from 'app/modules/summarySettings/sliceSummarySettings';
import { history } from 'app/store/browserHistory';
import sarsSliceReducer, {
  SARS_SLICE_NAME,
} from 'app/modules/fincenSar/sliceSars';
import orgSwitcherReducer, {
  ORG_SWITCHER_SLICE_NAME,
} from 'app/modules/orgSwitcher/sliceOrgSwitcher';
import rulesAdminReducer, {
  RULES_ADMIN_NAME,
} from 'app/modules/rulesAdmin/sliceRulesAdmin';
import attachmentsSliceReducer, {
  ATTACHMENTS_SLICE_NAME,
} from 'app/modules/attachmentsRefresh/sliceAttachments';
import entitiesSliceReducer, {
  ENTITIES_SLICE_NAME,
} from 'app/modules/entitiesRefresh/sliceEntities';
import filingsSliceReducer, {
  FILINGS_SLICE_NAME,
} from 'app/modules/filings/sliceFilings';
import sessionSliceReducer, {
  SESSION_SLICE_NAME,
} from 'app/modules/session/sliceSession';
import fincenSarReducer, {
  FINCEN_SAR_SLICE_NAME,
} from 'app/modules/fincenSarNew/sliceFincenSar';
import sliceFincenCtr, {
  FINCEN_CTR_SLICE_NAME,
} from 'app/modules/fincenCtr/sliceFincenCtr';
import alertsSliceReducer, {
  ALERTS_SLICE_NAME,
} from 'app/modules/alerts/sliceAlerts';
import narrativeTemplatesSliceReducer, {
  NARRATIVE_TEMPLATES_SLICE_NAME,
} from 'app/modules/narrativeTemplates/sliceNarrativeTemplates';
import narrativesSliceReducer, {
  NARRATIVES_SLICE_NAME,
} from 'app/modules/narratives/sliceNarratives';
import subSecondRuleCreateUISliceReducer, {
  SUB_SECOND_RULE_CREATE_UI_SLICE_NAME,
} from 'app/modules/detectionModels/sliceSubSecondRuleCreateUI';
import realTimeMonitoringRulesSliceReducer, {
  REAL_TIME_MONITORING_RULES_SLICE_NAME,
} from 'app/modules/detectionModels/sliceRealTimeMonitoringRules';
import ruleContentSliceReducer, {
  RULE_CONTENT_SLICE_NAME,
} from 'app/modules/detectionModels/sliceRuleContent';
import sliceSidePanel, {
  SIDE_PANEL_NAME,
} from 'app/shared/components/SidePanel/sliceSidePanel';
import instrumentReducer, {
  INSTRUMENTS_SLICE_NAME,
} from 'app/modules/instruments/sliceInstruments';
import transactionSliceReducer, {
  TRANSACTIONS_SLICE_NAME,
} from 'app/modules/transactions/sliceTransactions';
import {
  name as networkAnalysisName,
  reducer as networkAnalysisReducer,
} from 'app/modules/networkAnalysis/sliceNetworkAnalysis';
import {
  name as gaBannerSliceName,
  reducer as gaBannerReducer,
} from 'app/shared/components/GaBanner/sliceGaBanner';
import actionEventsSliceReducer, {
  ACTION_EVENTS_SLICE_NAME,
} from 'app/modules/actionEvents/sliceActionEvents';
import txnAnalysisSliceReducer, {
  TXN_ANALYSIS_SLICE_NAME,
} from 'app/modules/txnAnalysis/sliceTxnAnalysis';
import watchlistsSliceReducer, {
  WATCHLISTS_SLICE_NAME,
} from 'app/modules/watchlists/sliceWatchlists';
import fraudDashboardSliceReducer, {
  FRAUD_DASHBOARD_SLICE_NAME,
} from 'app/modules/fraudDashboard/slice';
import rtmRuleBuilderFormValuesSliceReducer, {
  RTM_RULE_BUILDER_FORM_VALUES_SLICE_NAME,
} from 'app/modules/detectionModels/sliceRtmRuleBuilderFormValues';
import {
  name as NOTES_SLICE_NAME,
  reducer as notesSliceReducer,
} from 'app/modules/notesNew/slice';

// Clear stale location state
if (history.location && history.location.state) {
  const state = {};
  history.replace({ ...history.location, state });
}

export const rootReducer = combineReducers({
  agents: agentsReducer,
  alerts: alertsReducer,
  [ALERTS_SLICE_NAME]: alertsSliceReducer,
  [ATTACHMENTS_SLICE_NAME]: attachmentsSliceReducer,
  auditTrail: auditTrailReducer,
  blacklists: blacklistsReducer,
  cases: casesReducer,
  [CASES_SLICE_NAME]: casesSliceReducer,
  censorship: censorshipReducer,
  comments: commentsReducer,
  customChecklist: customChecklistReducer,
  [investigationChecklistSliceName]: investigationChecklistReducer,
  deadlines: deadlinesReducer,
  dropdown: dropdownReducer,
  entities: entitiesReducer,
  [ENTITIES_SLICE_NAME]: entitiesSliceReducer,
  eventFilters: eventFiltersReducer,
  filters: filtersReducer,
  [FILINGS_SLICE_NAME]: filingsSliceReducer,
  [gaBannerSliceName]: gaBannerReducer,
  goAML: goAMLReducer,
  insights: insightsReducer,
  [INVESTIGATIONS_SLICE_NAME]: investigationsReducer,
  loading: loadingReducer,
  navigator: navigatorReducer,
  notes: notesReducer,
  [NOTIFICATIONS_SLICE_NAME]: notificationsSliceReducer,
  permissions: permissionsReducer,
  [DEVTOOLS_SLICE_NAME]: devtoolsReducer,
  [REAL_TIME_MONITORING_RULES_SLICE_NAME]: realTimeMonitoringRulesSliceReducer,
  router: connectRouter(history),
  rules: immerRulesReducer,
  [RULE_CONTENT_SLICE_NAME]: ruleContentSliceReducer,
  sars: sarsReducer,
  search: searchReducer,
  settings: settingsReducer,
  session: sessionReducer,
  [SESSION_SLICE_NAME]: sessionSliceReducer,
  [SIDE_PANEL_NAME]: sliceSidePanel,
  [SIDEBAR_SLICE_NAME]: sidebarReducer,
  [SUB_SECOND_RULE_CREATE_UI_SLICE_NAME]: subSecondRuleCreateUISliceReducer,
  team: teamReducer,
  toasts: toastsReducer,
  [THEME_SLICE_NAME]: themeSliceReducer,
  transactions: transactionsReducer,
  uploads: uploadsReducer,
  webhooks: webhooksReducer,
  workflows: workflowsReducer,
  detectionModels: detectionModelsReducer,
  tableConfig: tableConfigReducer,
  featureFlags: featureFlagsReducer,
  [FFIP_NAME]: flatFileIngestionReducer,
  [DATA_MAPPING_NAME]: dataMappingReducer,
  [AGENTS_SLICE_NAME]: agentsSliceReducer,
  [IMPERSONATION_SLICE_NAME]: impersonationSliceReducer,
  [SEARCH_SLICE_NAME]: searchSliceReducer,
  [DETECTION_MODELS_SLICE_NAME]: detectionModelsSliceReducer,
  [DETECTION_MODEL_DETAILS_SLICE_NAME]: detectionModelDetailsSliceReducer,
  [RTM_RULE_BUILDER_FORM_VALUES_SLICE_NAME]:
    rtmRuleBuilderFormValuesSliceReducer,
  [FILE_EXPORTS_SLICE_NAME]: fileExportsRefreshReducer,
  [RELATIONSHIPS_SLICE_NAME]: relationshipsSliceReducer,
  [TAGS_SLICE_NAME]: tagsSliceReducer,
  [EVENTS_SLICE_NAME]: eventsSliceReducer,
  [TEAMS_SLICE_NAME]: teamsSliceReducer,
  [API_KEYS_SLICE_NAME]: apiKeysSliceReducer,
  [ORG_SETTINGS_SLICE_NAME]: orgSettingsSliceReducer,
  [DISPOSITIONS_SLICE_NAME]: dispositionsSliceReducer,
  [QUEUES_SLICE_NAME]: queuesSliceReducer,
  [dataSettingsName]: dataSettingsReducer,
  [SARS_SLICE_NAME]: sarsSliceReducer,
  [tableSettingsName]: tableSettingsReducer,
  [ORG_SWITCHER_SLICE_NAME]: orgSwitcherReducer,
  [RULES_ADMIN_NAME]: rulesAdminReducer,
  [FINCEN_SAR_SLICE_NAME]: fincenSarReducer,
  [FINCEN_CTR_SLICE_NAME]: sliceFincenCtr,
  [NARRATIVE_TEMPLATES_SLICE_NAME]: narrativeTemplatesSliceReducer,
  [NARRATIVES_SLICE_NAME]: narrativesSliceReducer,
  [summarySettingsName]: summarySettingsReducer,
  [settingsImportExportName]: settingsImportExportReducer,
  [INSTRUMENTS_SLICE_NAME]: instrumentReducer,
  [TRANSACTIONS_SLICE_NAME]: transactionSliceReducer,
  [networkAnalysisName]: networkAnalysisReducer,
  [ACTION_EVENTS_SLICE_NAME]: actionEventsSliceReducer,
  [TXN_ANALYSIS_SLICE_NAME]: txnAnalysisSliceReducer,
  [WATCHLISTS_SLICE_NAME]: watchlistsSliceReducer,
  [FRAUD_DASHBOARD_SLICE_NAME]: fraudDashboardSliceReducer,
  [NOTES_SLICE_NAME]: notesSliceReducer,
});

// really nifty method for resetting the most of the entire redux state
// https://alligator.io/redux/reset-state-redux/
const wrappedRootReducer = (state: RootState, action) => {
  let newState = { ...state } as Partial<RootState>;
  if (action.type === '@@rootReducer/RESET_APP') {
    newState = {
      session: state.session,
      featureFlags: state.featureFlags,
    } as any;
  }

  return rootReducer(newState as RootState, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default wrappedRootReducer;
