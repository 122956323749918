import {
  ActorChip,
  AssetChip,
} from 'app/modules/auditService/components/Chips';
import {
  ActorChipProps,
  AssetChipComponentProps,
  AuditLogActor,
  AuditLogAsset,
  GenericChipProps,
  isDeadlineAuditLogAsset,
} from 'app/modules/auditService/types';
import { DeadlineType } from 'app/modules/deadlines/models';
import { QueueType } from 'app/modules/queues/models';
import { U21Typography } from 'app/shared/u21-ui/components';
import pluralize from 'pluralize';
import { FC, Fragment, Key } from 'react';

interface ContentProps {
  actor: AuditLogActor;
  asset: AuditLogAsset;
  details: AuditLogAsset[];
}

const getActionKey = (action: AssetChipComponentProps | string): Key => {
  if (typeof action === 'string') {
    return action;
  }
  if (action.type === 'date') {
    return action.date;
  }
  if (action.type === 'generic') {
    return action.text;
  }
  if (action.type === 'deadline_internal') {
    return action.deadlineType;
  }
  return action.id;
};

const AuditLog = ({
  actor,
  action,
}: {
  actor: ActorChipProps;
  action: (AssetChipComponentProps | string)[];
}) => (
  <U21Typography>
    <ActorChip {...actor} />
    {action.map((logComponent) => (
      <Fragment key={getActionKey(logComponent)}>
        {' '}
        {typeof logComponent === 'string' ? (
          logComponent
        ) : (
          <AssetChip {...logComponent} />
        )}
      </Fragment>
    ))}
  </U21Typography>
);

export const AUDIT_TRAIL_ACTION_TO_CONTENT: Record<string, FC<ContentProps>> = {
  // Team Actions
  'team.create': ({ actor, asset: team }: ContentProps) => {
    return <AuditLog actor={actor} action={['created', team]} />;
  },
  'team.add_agent': ({ actor, asset: team, details: agents }: ContentProps) => {
    return <AuditLog actor={actor} action={['added', ...agents, 'to', team]} />;
  },
  'team.remove_agent': ({
    actor,
    asset: team,
    details: agents,
  }: ContentProps) => {
    return (
      <AuditLog actor={actor} action={['removed', ...agents, 'from', team]} />
    );
  },
  'team.add_permission': ({
    actor,
    asset: team,
    details: permissions,
  }: ContentProps) => {
    return permissions.length > 0 ? (
      <AuditLog
        actor={actor}
        action={[
          'added',
          ...permissions,
          pluralize('permission', permissions.length),
          'to',
          team,
        ]}
      />
    ) : null;
  },
  'team.remove_permission': ({
    actor,
    asset: team,
    details: permissions,
  }: ContentProps) => {
    return permissions.length > 0 ? (
      <AuditLog
        actor={actor}
        action={[
          'removed',
          ...permissions,
          pluralize('permission', permissions.length),
          'from',
          team,
        ]}
      />
    ) : null;
  },
  // Case Actions
  'case.create': ({ actor, asset: caseAsset }: ContentProps) => {
    return <AuditLog actor={actor} action={['created', caseAsset]} />;
  },
  'case.open': ({ actor, asset: caseAsset }: ContentProps) => {
    return <AuditLog actor={actor} action={['opened', caseAsset]} />;
  },
  'case.close': ({ actor, asset: caseAsset }: ContentProps) => {
    return <AuditLog actor={actor} action={['closed', caseAsset]} />;
  },
  'case.in_review': ({ actor, asset: caseAsset }: ContentProps) => {
    return (
      <AuditLog actor={actor} action={['marked', caseAsset, 'for review']} />
    );
  },
  'case.add_entity': ({
    actor,
    asset: caseAsset,
    details: entities,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'added',
          ...entities,
          pluralize('entity', entities.length),
          'to',
          caseAsset,
        ]}
      />
    );
  },
  'case.remove_entity': ({
    actor,
    asset: caseAsset,
    details: entities,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'removed',
          ...entities,
          pluralize('entity', entities.length),
          'from',
          caseAsset,
        ]}
      />
    );
  },
  'case.add_alert': ({
    actor,
    asset: caseAsset,
    details: alerts,
  }: ContentProps) => {
    return (
      <AuditLog actor={actor} action={['added', ...alerts, 'to', caseAsset]} />
    );
  },
  'case.remove_alert': ({
    actor,
    asset: caseAsset,
    details: alerts,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['removed', ...alerts, 'from', caseAsset]}
      />
    );
  },
  'case.create_sar': ({ actor, asset: caseAsset, details }: ContentProps) => {
    const [filing] = details;
    return (
      <AuditLog actor={actor} action={['created', filing, 'from', caseAsset]} />
    );
  },
  'case.narrative_create': ({ actor, asset: caseAsset }: ContentProps) => {
    return (
      <AuditLog actor={actor} action={['added a narrative to', caseAsset]} />
    );
  },
  'case.narrative_edit': ({ actor, asset: caseAsset }: ContentProps) => {
    return (
      <AuditLog actor={actor} action={['edited a narrative on', caseAsset]} />
    );
  },
  'case.narrative_delete': ({ actor, asset: caseAsset }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['deleted a narrative from', caseAsset]}
      />
    );
  },
  'case.assign': ({ actor, asset: caseAsset, details }: ContentProps) => {
    const [agent] = details;
    const typedAgent: AuditLogAsset = agent?.type
      ? agent
      : { type: 'agent', id: -1, name: 'No one', isMissing: false }; // Handle assigments to "no one"
    return (
      <AuditLog
        actor={actor}
        action={['assigned', caseAsset, 'to', typedAgent]}
      />
    );
  },
  'case.change_queue': ({ actor, asset: caseAsset, details }: ContentProps) => {
    const [queue] = details;
    if (!queue) return null;
    const caseQueue: AssetChipComponentProps = {
      ...queue,
      type: 'queue_internal',
      queueType: QueueType.CASE_QUEUE,
    };
    return (
      <AuditLog actor={actor} action={['moved', caseAsset, 'to', caseQueue]} />
    );
  },
  'case.add_tag': ({
    actor,
    asset: caseAsset,
    details: tags,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'added',
          ...tags,
          pluralize('tag', tags.length),
          'to',
          caseAsset,
        ]}
      />
    );
  },
  'case.remove_tag': ({
    actor,
    asset: caseAsset,
    details: tags,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'removed',
          ...tags,
          pluralize('tag', tags.length),
          'from',
          caseAsset,
        ]}
      />
    );
  },
  'case.add_txn_event': ({
    actor,
    asset: caseAsset,
    details: events,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'added',
          ...events,
          pluralize('transaction', events.length),
          'to',
          caseAsset,
        ]}
      />
    );
  },
  'case.remove_txn_event': ({
    actor,
    asset: caseAsset,
    details: events,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'removed',
          ...events,
          pluralize('transaction', events.length),
          'from',
          caseAsset,
        ]}
      />
    );
  },
  'case.add_action_event': ({
    actor,
    asset: caseAsset,
    details: events,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'added',
          ...events,
          pluralize('action', events.length),
          'to',
          caseAsset,
        ]}
      />
    );
  },
  'case.remove_action_event': ({
    actor,
    asset: caseAsset,
    details: events,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'removed',
          ...events,
          pluralize('action', events.length),
          'from',
          caseAsset,
        ]}
      />
    );
  },
  'case.add_attachment': ({
    actor,
    asset: caseAsset,
    details: attachments,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['uploaded', ...attachments, 'to', caseAsset]}
      />
    );
  },
  'case.remove_attachment': ({
    actor,
    asset: caseAsset,
    details: attachments,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['removed', ...attachments, 'from', caseAsset]}
      />
    );
  },
  'case.disposition': ({ actor, asset: caseAsset, details }: ContentProps) => {
    const [disposition] = details;
    return disposition?.name ? (
      <AuditLog
        actor={actor}
        action={['dispositioned', caseAsset, 'as', disposition]}
      />
    ) : (
      <AuditLog actor={actor} action={['dispositioned', caseAsset]} />
    );
  },
  'case.subdisposition': ({
    actor,
    asset: caseAsset,
    details: subdispositions,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['subdispositioned', caseAsset, 'as', ...subdispositions]}
      />
    );
  },
  'case.add_watcher': ({
    actor,
    asset: caseAsset,
    details: watchers,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'added',
          ...watchers,
          'as',
          watchers.length > 1 ? 'watchers' : 'a watcher',
          'for',
          caseAsset,
        ]}
      />
    );
  },
  'case.remove_watcher': ({
    actor,
    asset: caseAsset,
    details: watchers,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'removed',
          ...watchers,
          'as',
          watchers.length > 1 ? 'watchers' : 'a watcher',
          'for',
          caseAsset,
        ]}
      />
    );
  },
  'case.trigger_action': ({
    actor,
    asset: caseAsset,
    details: actions,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['triggered', ...actions, 'on', caseAsset]}
      />
    );
  },
  'case.set_deadline': ({ actor, asset: caseAsset, details }: ContentProps) => {
    const [deadline] = details;
    if (!deadline) return null;
    if (!isDeadlineAuditLogAsset(deadline)) return null;
    const { custom_data: customData } = deadline;
    const { date, type } = customData ?? {};

    if (type === DeadlineType.NO_DEADLINE) {
      return (
        <AuditLog actor={actor} action={['removed deadline from', caseAsset]} />
      );
    }

    const deadlineAsset: AssetChipComponentProps = {
      type: 'deadline_internal',
      deadlineType: type,
    };
    const dateAsset: AssetChipComponentProps = {
      type: 'date',
      date,
    };
    return (
      <AuditLog
        actor={actor}
        action={['set', deadlineAsset, 'of', dateAsset, 'to', caseAsset]}
      />
    );
  },
  'case.update_custom_data': ({ actor, asset: caseAsset }: ContentProps) => {
    return (
      <AuditLog actor={actor} action={['updated custom data on', caseAsset]} />
    );
  },
  'case.update_disposition_notes': ({
    actor,
    asset: caseAsset,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['updated disposition notes on', caseAsset]}
      />
    );
  },
  'case.update_txn_fraud_metadata': ({
    actor,
    asset: caseAsset,
  }: ContentProps) => {
    return (
      <AuditLog actor={actor} action={['updated fraud losses on', caseAsset]} />
    );
  },

  // Alerts
  'alert.create': ({ actor, asset: alert }: ContentProps) => {
    return <AuditLog actor={actor} action={['created', alert]} />;
  },
  'alert.open': ({ actor, asset: alert }: ContentProps) => {
    return <AuditLog actor={actor} action={['opened', alert]} />;
  },
  'alert.close': ({ actor, asset: alert }: ContentProps) => {
    return <AuditLog actor={actor} action={['closed', alert]} />;
  },
  'alert.in_review': ({ actor, asset: alert }: ContentProps) => {
    return <AuditLog actor={actor} action={['marked', alert, 'for review']} />;
  },
  'alert.create_sar': ({ actor, asset: alert, details }: ContentProps) => {
    const [sar] = details;
    return <AuditLog actor={actor} action={['created', sar, 'from', alert]} />;
  },
  'alert.narrative_create': ({ actor, asset: alert }: ContentProps) => {
    return <AuditLog actor={actor} action={['added a narrative to', alert]} />;
  },
  'alert.narrative_edit': ({ actor, asset: alert }: ContentProps) => {
    return <AuditLog actor={actor} action={['edited a narrative on', alert]} />;
  },
  'alert.narrative_delete': ({ actor, asset: alert }: ContentProps) => {
    return (
      <AuditLog actor={actor} action={['deleted a narrative from', alert]} />
    );
  },
  'alert.create_case': ({ actor, details }: ContentProps) => {
    const [createdCase] = details;
    return <AuditLog actor={actor} action={['created', createdCase]} />;
  },
  'alert.assign': ({ actor, asset: alert, details }: ContentProps) => {
    const [agent] = details;
    const typedAgent: AuditLogAsset = agent?.type
      ? agent
      : { type: 'agent', id: -1, name: 'No one', isMissing: false }; // Handle assigments to "no one"
    return (
      <AuditLog actor={actor} action={['assigned', alert, 'to', typedAgent]} />
    );
  },
  'alert.change_queue': ({ actor, asset: alert, details }: ContentProps) => {
    const [queue] = details;
    if (!queue) return null;
    const alertQueue: AssetChipComponentProps = {
      ...queue,
      type: 'queue_internal',
      queueType: QueueType.ALERT_QUEUE,
    };
    return (
      <AuditLog actor={actor} action={['moved', alert, 'to', alertQueue]} />
    );
  },
  'alert.add_rule': ({ actor, asset: alert, details: rules }: ContentProps) => {
    return <AuditLog actor={actor} action={['added', ...rules, 'to', alert]} />;
  },
  'alert.remove_rule': ({
    actor,
    asset: alert,
    details: rules,
  }: ContentProps) => {
    return (
      <AuditLog actor={actor} action={['removed', ...rules, 'from', alert]} />
    );
  },
  'alert.add_tag': ({ actor, asset: alert, details: tags }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['added', ...tags, pluralize('tag', tags.length), 'to', alert]}
      />
    );
  },
  'alert.remove_tag': ({
    actor,
    asset: alert,
    details: tags,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'removed',
          ...tags,
          pluralize('tag', tags.length),
          'from',
          alert,
        ]}
      />
    );
  },
  'alert.add_attachment': ({
    actor,
    asset: alert,
    details: attachments,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['uploaded', ...attachments, 'to', alert]}
      />
    );
  },
  'alert.remove_attachment': ({
    actor,
    asset: alert,
    details: attachments,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['removed', ...attachments, 'from', alert]}
      />
    );
  },
  'alert.disposition': ({ actor, asset: alert, details }: ContentProps) => {
    const [disposition] = details;
    return disposition?.name ? (
      <AuditLog
        actor={actor}
        action={['dispositioned', alert, 'as', disposition]}
      />
    ) : (
      <AuditLog actor={actor} action={['dispositioned', alert]} />
    );
  },
  'alert.subdisposition': ({
    actor,
    asset: alert,
    details: subdispositions,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['subdispositioned', alert, 'as', ...subdispositions]}
      />
    );
  },
  'alert.add_entity': ({
    actor,
    asset: alert,
    details: entities,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'added',
          ...entities,
          pluralize('entity', entities.length),
          'to',
          alert,
        ]}
      />
    );
  },
  'alert.remove_entity': ({
    actor,
    asset: alert,
    details: entities,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'removed',
          ...entities,
          pluralize('entity', entities.length),
          'from',
          alert,
        ]}
      />
    );
  },
  'alert.add_txn_event': ({
    actor,
    asset: alert,
    details: events,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'added',
          ...events,
          pluralize('transaction', events.length),
          'to',
          alert,
        ]}
      />
    );
  },
  'alert.remove_txn_event': ({
    actor,
    asset: alert,
    details: events,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'removed',
          ...events,
          pluralize('transaction', events.length),
          'from',
          alert,
        ]}
      />
    );
  },
  'alert.add_action_event': ({
    actor,
    asset: alert,
    details: events,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'added',
          ...events,
          pluralize('action', events.length),
          'to',
          alert,
        ]}
      />
    );
  },
  'alert.remove_action_event': ({
    actor,
    asset: alert,
    details: events,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'removed',
          ...events,
          pluralize('action', events.length),
          'from',
          alert,
        ]}
      />
    );
  },
  'alert.add_instrument': ({
    actor,
    asset: alert,
    details: instruments,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'added',
          ...instruments,
          pluralize('instrument', instruments.length),
          'to',
          alert,
        ]}
      />
    );
  },
  'alert.remove_instrument': ({
    actor,
    asset: alert,
    details: instruments,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'removed',
          ...instruments,
          pluralize('instrument', instruments.length),
          'from',
          alert,
        ]}
      />
    );
  },
  'alert.trigger_action': ({
    actor,
    asset: alert,
    details: actions,
  }: ContentProps) => {
    return (
      <AuditLog actor={actor} action={['triggered', ...actions, 'on', alert]} />
    );
  },
  'alert.set_deadline': ({ actor, asset: alert, details }: ContentProps) => {
    const [deadline] = details;
    if (!deadline) return null;
    if (!isDeadlineAuditLogAsset(deadline)) return null;
    const { custom_data: customData } = deadline;
    const { date, type } = customData ?? {};

    if (type === DeadlineType.NO_DEADLINE) {
      return (
        <AuditLog actor={actor} action={['removed deadline from', alert]} />
      );
    }

    const deadlineAsset: AssetChipComponentProps = {
      type: 'deadline_internal',
      deadlineType: type,
    };
    const dateAsset: AssetChipComponentProps = {
      type: 'date',
      date,
    };
    return (
      <AuditLog
        actor={actor}
        action={['set', deadlineAsset, 'of', dateAsset, 'to', alert]}
      />
    );
  },
  'alert.update_custom_data': ({ actor, asset: alert }: ContentProps) => {
    return (
      <AuditLog actor={actor} action={['updated custom data on', alert]} />
    );
  },
  'alert.update_disposition_notes': ({ actor, asset: alert }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['updated disposition notes on', alert]}
      />
    );
  },
  'alert.run_ai_investigation': ({ actor, asset: alert }: ContentProps) => {
    return (
      <AuditLog actor={actor} action={['ran AI investigation for', alert]} />
    );
  },

  // CTRs
  'fincen_ctr.create': ({ actor, asset: ctr, details }: ContentProps) => {
    const [fromArticle] = details;
    return fromArticle ? (
      <AuditLog actor={actor} action={['created', ctr, 'from', fromArticle]} />
    ) : (
      <AuditLog actor={actor} action={['created', ctr]} />
    );
  },
  'fincen_ctr.edit': ({ actor, asset: ctr }: ContentProps) => {
    // TODO show the edited fields
    return <AuditLog actor={actor} action={['edited', ctr]} />;
  },
  'fincen_ctr.lock': ({ actor, asset: ctr }: ContentProps) => {
    const status: GenericChipProps = {
      type: 'generic',
      text: 'Ready to file',
    };
    return (
      <AuditLog
        actor={actor}
        action={['updated the status of', ctr, 'to', status]}
      />
    );
  },
  'fincen_ctr.unlock': ({ actor, asset: ctr }: ContentProps) => {
    const status: GenericChipProps = {
      type: 'generic',
      text: 'In progress',
    };
    return (
      <AuditLog
        actor={actor}
        action={['updated the status of', ctr, 'to', status]}
      />
    );
  },
  'fincen_ctr.create_batch': ({ actor, asset: ctr, details }: ContentProps) => {
    const [batch] = details;
    // TODO add a chip, we don't have a page dedicated to batches
    return (
      <AuditLog
        actor={actor}
        action={[`created batch ${batch.id} from`, ctr]}
      />
    );
  },
  'fincen_ctr.add_attachment': ({
    actor,
    asset: ctr,
    details: attachments,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['uploaded', ...attachments, 'to', ctr]}
      />
    );
  },
  'fincen_ctr.remove_attachment': ({
    actor,
    asset: ctr,
    details: attachments,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['removed', ...attachments, 'from', ctr]}
      />
    );
  },
  // GoAML Actions
  'goaml.create': ({ actor, asset: filing }: ContentProps) => {
    return <AuditLog actor={actor} action={['created', filing]} />;
  },
  'entity.remove_attachment': ({
    actor,
    asset: entity,
    details: attachments,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['removed', ...attachments, 'from', entity]}
      />
    );
  },
  // Risk Ratings
  'risk_model.edit': ({ actor, asset: riskModel }: ContentProps) => {
    return <AuditLog actor={actor} action={['edited', riskModel]} />;
  },
  'risk_model.activate': ({ actor, asset: riskModel }: ContentProps) => {
    return <AuditLog actor={actor} action={['activated', riskModel]} />;
  },
  'risk_model.deactivate': ({ actor, asset: riskModel }: ContentProps) => {
    return <AuditLog actor={actor} action={['deactivated', riskModel]} />;
  },
  'risk_model.create': ({ actor, asset: riskModel }: ContentProps) => {
    return <AuditLog actor={actor} action={['created', riskModel]} />;
  },
  'risk_model.validate': ({ actor, asset: riskModel }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['started a validation for', riskModel]}
      />
    );
  },

  // Rules
  'rule.create': ({ actor, asset: rule }: ContentProps) => {
    return <AuditLog actor={actor} action={['created', rule]} />;
  },
  'rule.deactivate': ({ actor, asset: rule }: ContentProps) => {
    return <AuditLog actor={actor} action={['deactivated', rule]} />;
  },
  'rule.deploy': ({ actor, asset: rule }: ContentProps) => {
    return <AuditLog actor={actor} action={['deployed', rule]} />;
  },
  'rule.shadow_deploy': ({ actor, asset: rule }: ContentProps) => {
    return (
      <AuditLog actor={actor} action={['deployed', rule, 'in shadow mode']} />
    );
  },
  'rule.duplicate': ({ actor, asset: rule }: ContentProps) => {
    return <AuditLog actor={actor} action={['duplicated', rule]} />;
  },
  'rule.edit': ({ actor, asset: rule }: ContentProps) => {
    // Legacy action, not logged anywhere
    return <AuditLog actor={actor} action={['edited', rule]} />;
  },
  'rule.set_title': ({ actor, asset: rule }: ContentProps) => {
    return <AuditLog actor={actor} action={['updated title on', rule]} />;
  },
  'rule.set_description': ({ actor, asset: rule }: ContentProps) => {
    return <AuditLog actor={actor} action={['updated description on', rule]} />;
  },
  'rule.add_queue': ({ actor, asset: rule, details }: ContentProps) => {
    const [queue] = details;
    if (!queue) return null;
    const alertQueue: AssetChipComponentProps = {
      ...queue,
      type: 'queue_internal',
      queueType: QueueType.ALERT_QUEUE,
    };
    return (
      <AuditLog actor={actor} action={['added', alertQueue, 'to', rule]} />
    );
  },
  'rule.remove_queue': ({ actor, asset: rule, details }: ContentProps) => {
    const [queue] = details;
    if (!queue) return null;
    const alertQueue: AssetChipComponentProps = {
      ...queue,
      type: 'queue_internal',
      queueType: QueueType.ALERT_QUEUE,
    };
    return (
      <AuditLog actor={actor} action={['removed', alertQueue, 'from', rule]} />
    );
  },
  'rule.add_tag': ({ actor, asset: rule, details: tags }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['added', ...tags, pluralize('tag', tags.length), 'to', rule]}
      />
    );
  },
  'rule.remove_tag': ({ actor, asset: rule, details: tags }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={[
          'removed',
          ...tags,
          pluralize('tag', tags.length),
          'from',
          rule,
        ]}
      />
    );
  },

  // FinCEN SARs
  'sar.add_attachment': ({
    actor,
    asset: sar,
    details: attachments,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['uploaded', ...attachments, 'to', sar]}
      />
    );
  },
  'sar.create': ({ actor, asset: sar }: ContentProps) => {
    return <AuditLog actor={actor} action={['created', sar]} />;
  },
  'sar.edit': ({ actor, asset: sar }: ContentProps) => {
    return <AuditLog actor={actor} action={['edited', sar]} />;
  },
  'sar.set_deadline': ({ actor, asset: sar, details }: ContentProps) => {
    const [deadline] = details;
    if (!deadline) return null;
    if (!isDeadlineAuditLogAsset(deadline)) return null;
    const { custom_data: customData } = deadline;
    const { date, type } = customData ?? {};

    if (type === DeadlineType.NO_DEADLINE) {
      return <AuditLog actor={actor} action={['removed deadline from', sar]} />;
    }

    const deadlineAsset: AssetChipComponentProps = {
      type: 'deadline_internal',
      deadlineType: type,
    };
    const dateAsset: AssetChipComponentProps = {
      type: 'date',
      date,
    };
    return (
      <AuditLog
        actor={actor}
        action={['set', deadlineAsset, 'of', dateAsset, 'to', sar]}
      />
    );
  },
  'sar.submit': ({ actor, asset: sar }: ContentProps) => {
    return <AuditLog actor={actor} action={['submitted', sar]} />;
  },
  'sar.remove_attachment': ({
    actor,
    asset: sar,
    details: attachments,
  }: ContentProps) => {
    return (
      <AuditLog
        actor={actor}
        action={['removed', ...attachments, 'from', sar]}
      />
    );
  },
  'sar.assign': ({ actor, asset: sar, details }: ContentProps) => {
    const [agent] = details;
    const typedAgent: AuditLogAsset = agent?.type
      ? agent
      : { type: 'agent', id: -1, name: 'No one', isMissing: false }; // Handle assigments to "no one"
    return (
      <AuditLog actor={actor} action={['assigned', sar, 'to', typedAgent]} />
    );
  },
  // Deprecated, left in for backwards compatibility
  'sar.reassign': ({ actor, asset: sar, details }: ContentProps) => {
    const [agent] = details;
    const typedAgent: AuditLogAsset = agent?.type
      ? agent
      : { type: 'agent', id: -1, name: 'No one', isMissing: false }; // Handle assigments to "no one"
    return (
      <AuditLog actor={actor} action={['assigned', sar, 'to', typedAgent]} />
    );
  },
  'sar.requeue': ({ actor, asset: sar, details }: ContentProps) => {
    const [queue] = details;
    if (!queue) return null;
    const sarQueue: AssetChipComponentProps = {
      ...queue,
      type: 'queue_internal',
      queueType: QueueType.SAR_QUEUE,
    };
    return <AuditLog actor={actor} action={['moved', sar, 'to', sarQueue]} />;
  },
  'sar.duplicate': ({ actor, asset: sar }: ContentProps) => {
    return <AuditLog actor={actor} action={['duplicated', sar]} />;
  },
  'sar.lock': ({ actor, asset: sar }: ContentProps) => {
    const status: GenericChipProps = {
      type: 'generic',
      text: 'Ready for review',
    };
    return (
      <AuditLog
        actor={actor}
        action={['updated the status of', sar, 'to', status]}
      />
    );
  },
  'sar.unlock': ({ actor, asset: sar }: ContentProps) => {
    const status: GenericChipProps = {
      type: 'generic',
      text: 'In progress',
    };
    return (
      <AuditLog
        actor={actor}
        action={['updated the status of', sar, 'to', status]}
      />
    );
  },
};
