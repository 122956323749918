export const ROOT_DOCS = 'https://docs.unit21.ai';
export const CHANGELOG_DOCS = 'https://docs.unit21.ai/changelog';

// Create Alert documentation
export const CREATE_ALERT_DOCS =
  'https://docs.unit21.ai/docs/create-an-alert-manually';
export const ALERT_NARRATIVE_TEMPLATES_DOCS =
  'https://docs.unit21.ai/docs/alert-narrative-templates';

// Custom Data documentation
export const CUSTOM_DATA_DOCS =
  'https://docs.unit21.ai/reference/best-practices-for-custom-data';

// Investigation Checklist
export const ALERT_INVESTIGATION_CHECKLIST_DOCS =
  'https://docs.unit21.ai/docs/how-to-create-an-alert-checklist';
export const CASE_INVESTIGATION_CHECKLIST_DOCS =
  'https://docs.unit21.ai/docs/how-to-create-a-case-checklist';

// Alert page documentation
export const ALERT_PAGE_DOCS = 'https://docs.unit21.ai/docs/alerts';
export const ALERT_SCORE_DOCS = 'https://docs.unit21.ai/docs/alert-scores';

// Case page documentation
export const CASE_PAGE_DOCS = 'https://docs.unit21.ai/docs/cases';

// Detection model variable creation documentation
export const VARIABLE_PAGE_DOCS =
  'https://docs.unit21.ai/docs/create-a-variable';
export const DYNAMIC_MODELS_PAGE_DOCS =
  'https://docs.unit21.ai/docs/how-to-use-the-dynamic-model';
export const MAKEUP_WINDOWS_PAGE_DOCS =
  'https://docs.unit21.ai/docs/make-up-windows';
