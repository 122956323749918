import { useFetchFraudLossTable } from 'app/modules/fraudDashboard/queries/useFetchFraudLossTable';
import {
  GetFraudLossPayload,
  GetFraudLossTablePayload,
} from 'app/modules/fraudDashboard/models';
import { U21Table, U21TableState } from 'app/shared/u21-ui/components';
import {
  DEFAULT_PAGE,
  DEFAULT_TABLE_PREFRENCES,
} from 'app/shared/u21-ui/components/display/table/constants';
import { createPaginationPayload } from 'app/shared/utils/table';
import { useMemo, useState } from 'react';
import { TXN_META_TABLE_COLUMNS } from 'app/modules/fraudDashboard/constants';

interface OwnProps {
  payload: GetFraudLossPayload;
  noDataComponent?: string;
}

export const FraudLossTable = ({ payload, noDataComponent }: OwnProps) => {
  const [paginationData, setPaginationData] = useState<U21TableState>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_TABLE_PREFRENCES.pageSize,
    sortBy: [{ id: 'event_time', desc: true }],
  });

  const paginatedPayload = useMemo<GetFraudLossTablePayload>(
    () => ({
      ...payload,
      ...createPaginationPayload(paginationData),
    }),
    [paginationData, payload],
  );

  const {
    data: tableData,
    isLoading: tableLoading,
    isError: isTableDataError,
  } = useFetchFraudLossTable(paginatedPayload);

  return (
    <U21Table
      manualPagination
      count={tableData?.count}
      columns={TXN_META_TABLE_COLUMNS}
      noDataComponent={
        isTableDataError
          ? 'Something went wrong. Please try again.'
          : noDataComponent
      }
      defaultSortBy={[{ desc: true, id: 'event_time' }]}
      defaultPage={DEFAULT_PAGE}
      defaultPageSize={DEFAULT_TABLE_PREFRENCES.pageSize}
      defaultColumnWidths={DEFAULT_TABLE_PREFRENCES.columnWidths}
      data={tableData?.results ?? []}
      onStateChange={setPaginationData}
      loading={tableLoading}
    />
  );
};
