import { ConditionalOperators } from 'app/modules/detectionModels/constants';
import { DropdownBuilderFormValues } from 'app/modules/detectionModels/components/DropdownBuilderForm/models';

export const DEFAULT_DROPDOWN_BUILDER_FORM_VALUES: DropdownBuilderFormValues =
  Object.freeze({
    facts: [],
    condition: {
      type: 'rule_condition_composite',
      operator: 'AND',
      conditions: [
        {
          type: 'rule_condition_composite',
          operator: ConditionalOperators.EQ,
          leftOperand: {
            operandType: 'select',
            value: {
              type: 'FIELD',
              field: '',
              datatype: '',
              model: '',
            },
          },
          rightOperand: null,
          betweenOperand: null,
        },
      ],
    },
  });
