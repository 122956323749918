// Models
import {
  CompositeQueryTreeNode,
  ConstantValue,
  DmAggregateFunction,
  DmEventSubtype,
  DmObjectToFlag,
  DmOperator,
  DurationValue,
  FieldValue,
  IRSchema,
  QueryTreeNode,
  VariableValue,
} from 'app/modules/rulesAdvanced/types';
import {
  AndOrCondition,
  Condition,
  DmEventByObjectFactOperand,
} from 'app/modules/detectionModels/components/DropdownBuilderForm/models';
import {
  Operand,
  OperandValue,
} from 'app/modules/rulesAdvanced/lib/SymbolTable';

// Constants
import {
  ConditionalOperators,
  DropdownBuilderFormFields,
  EMPTY_OPERAND_FIELD_VALUE,
  OBJECT_TO_FLAG_TO_MODEL,
} from 'app/modules/detectionModels/constants';

// Utils
import { isCompositeOperator } from 'app/modules/detectionModels/helpers';
import { MatchlistFieldValue } from 'app/modules/rulesAdvanced/types/RulesRepresentation';

// NOTE: Dropdown Builder helper functions
// Extracted from helpers.tsx to avoid circular dependencies
export const generateEmptyIrRule = (object: DmObjectToFlag): IRSchema => {
  return {
    facts: [],
    rule_condition: {
      type: 'rule_condition_composite',
      operands: [],
      operator: 'AND',
    },
    object,
    field_to_flag: {
      type: 'FLAG_FIELD',
      field: `${object === 'TXN_EVENT' ? 'internal_txn_type' : 'action_type'}`,
      model: OBJECT_TO_FLAG_TO_MODEL[object],
      values: [],
    },
  };
};

export const generateEventByObjectFactOperand = (
  id: string,
  aggregateFunction: DmAggregateFunction,
  eventSubtype: DmEventSubtype,
): DmEventByObjectFactOperand => {
  return {
    type: 'EVENT_BY_OBJECT_FACT',
    id,
    object: 'ENTITY',
    sender_receiver: 'SENDER',
    event_filters: {
      type: 'COMPOSITE',
      operands: [],
      operator: 'AND',
    },
    aggregate_function: aggregateFunction,
    aggregate_field: EMPTY_OPERAND_FIELD_VALUE,
    event_subtype: eventSubtype,
    window_end_offset: {
      type: 'DURATION',
      unit: 'HOUR',
      value: 0,
    },
    window_start_offset: {
      type: 'DURATION',
      unit: 'DAY',
      value: 0,
    },
  };
};

export const isAndOrCondition = (
  condition: AndOrCondition | Condition,
): condition is AndOrCondition => {
  return Object.hasOwn(condition, DropdownBuilderFormFields.CONDITIONS);
};

export const isCondition = (
  condition: AndOrCondition | Condition,
): condition is Condition => {
  return Object.hasOwn(condition, DropdownBuilderFormFields.LEFT_OPERAND);
};

export const isComposite = (
  operandValue: OperandValue | CompositeQueryTreeNode,
): operandValue is CompositeQueryTreeNode => {
  return operandValue?.type === 'rule_condition_composite';
};

export const isFieldValue = (
  operandValue: OperandValue | CompositeQueryTreeNode,
): operandValue is FieldValue => {
  return operandValue?.type === 'FIELD';
};

export const isConstantValue = (
  operandValue: OperandValue | QueryTreeNode,
): operandValue is ConstantValue => {
  return operandValue?.type === 'CONSTANT';
};

export const isAggregateVariable = (
  operandValue: OperandValue | QueryTreeNode,
): operandValue is VariableValue & { datatype: string } => {
  return operandValue?.type === 'VARIABLE';
};

export const isDurationValue = (
  operandValue: OperandValue | CompositeQueryTreeNode,
): operandValue is DurationValue => {
  return operandValue?.type === 'DURATION';
};

export const isMatchlistFieldValue = (
  operandValue: OperandValue | CompositeQueryTreeNode,
): operandValue is MatchlistFieldValue => {
  return operandValue?.type === 'MATCHLIST_FIELD';
};

// export const convertConditionsToQueryTreeNodes = ( TODO: chore/sc-76764/create-util-function-to-convert-fe-payload
//   condition: Operand,
// ): QueryTreeNode => {
//   if (!condition) return null;
//   if (isCompositeType(condition)) {
//     if (Object.hasOwn(condition, DropdownBuilderFormFields.CONDITIONS)) {
//      return condition[DropdownBuilderFormFields.CONDITIONS].map((nestedCondition) => convertConditionsToQueryTreeNodes(nestedCondition))
//     }
//     const { type, operator, leftOperand, rightOperand, betweenOperand } =
//       condition as Condition;

//     const operands = [
//       leftOperand,
//       convertConditionsToQueryTreeNodes(rightOperand),
//     ];
//     if (betweenOperand)
//       operands.push(convertConditionsToQueryTreeNodes(betweenOperand));

//     return {
//       type,
//       operands,
//       operator,
//       meta_ui: {
//         input_choice: 'dropdown',
//         ui_operator: operator,
//       },
//     };
//   }
//   return condition;
// };

// export const generateIRSchemaFromForm = ( TODO: chore/sc-76764/create-util-function-to-convert-fe-payload
//   value: DropdownBuilderFormValues,
// ): IRSchema => {
//   const {
//     condition,
//     eventSubTypes,
//     facts,
//     objectToFlag,
//   } = value;
//   const ruleCondition: CompositeQueryTreeNode = {
//     type: 'rule_condition_composite',
//     operator: condition.operator ?? 'AND',
//     operands: [],
//   };

//   ruleCondition.operands = condition.conditions.map((nestedCondition) =>
//     convertConditionsToQueryTreeNodes(nestedCondition),
//   );

//   return {
//     facts,
//     field_to_flag: {
//       type: 'FLAG_FIELD',
//       field: `${objectToFlag === 'TXN_EVENT' ? 'internal_txn_type' : 'action_type'}`,
//       model: OBJECT_TO_FLAG_TO_MODEL[objectToFlag],
//       values: eventSubTypes,
//     },
//     object: objectToFlag,
//     rule_condition: ruleCondition,
//   };
// };

export const generateNestedOperandKey = (groupIndex: number[]) => {
  let operatorKey: string = ``;
  groupIndex.forEach((gIndex) => {
    operatorKey = operatorKey.concat(
      `${operatorKey === '' ? '' : '.'}operands[${gIndex}]`,
    );
  });
  return operatorKey;
};

export const extractFactNames = (
  ruleCondition: CompositeQueryTreeNode,
): Set<string> => {
  const foundFacts: QueryTreeNode[] = ruleCondition.operands.filter(
    (operand) => operand?.type === 'VARIABLE',
  );
  const factNames: Set<string> = new Set();
  if (foundFacts.length) {
    foundFacts.forEach((variable) => {
      if (variable?.type === 'VARIABLE') factNames.add(variable.name);
    });
  }
  return factNames;
};

export const extractFactNamesFromGroup = (
  groupCondition: CompositeQueryTreeNode,
): Set<string> => {
  const factNames: Set<string> = new Set();
  groupCondition.operands.forEach((operand) => {
    if (operand?.type === 'rule_condition_composite') {
      if (isCompositeOperator(operand.operator))
        extractFactNamesFromGroup(operand).forEach((factName) =>
          factNames.add(factName),
        );
      // NOTE: still a "group" condition
      else
        extractFactNames(operand).forEach((factName) =>
          factNames.add(factName),
        );
    }
  });
  return factNames;
};

export const comparisonOperatorSideEffect = (
  operand: Operand | undefined,
): { uiOperator: DmOperator; operator: DmOperator } | undefined => {
  if (!operand) return undefined;
  let res;
  if (
    operand.dropdownValues?.length ||
    ['enum', 'multiselect', 'select'].includes(operand.operandType)
  ) {
    res = {
      uiOperator: ConditionalOperators.IN,
      operator: ConditionalOperators.ANY_IN,
    };
  } else if (['date', 'datetime'].includes(operand.operandType)) {
    res = {
      uiOperator: ConditionalOperators.GT,
      operator: ConditionalOperators.GT,
    };
  } else {
    res = {
      uiOperator: ConditionalOperators.EQ,
      operator: ConditionalOperators.EQ,
    };
  }

  return res;
};
