import {
  BulkRuleDeployMetaData,
  PaginatedAlerts,
  RetrieveValidationRuleAlertsPayload,
  RuleFiltersModel,
  RuleMetadata,
  RuleScenarioFilters,
} from 'app/modules/rules/models';
import {
  DmObjectToFlag,
  IRSchema,
} from 'app/modules/rulesAdvanced/types/RulesRepresentation';
import {
  OrgScenarioConfig,
  QueryBuilderConfig,
} from 'app/modules/orgSettings/models';
import { PaginationPayload } from 'app/shared/pagination/models';
import { EventHistogram } from 'app/shared/types/histogram';
import { ImmutableTree } from 'react-awesome-query-builder';
import { FullRuleResponse } from 'app/modules/rules/types';
import { WatchlistParameters } from 'app/modules/detectionModels/components/scenarioWidgets/watchlist/models';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';
import { RuleSpecification } from 'app/modules/rules/types/responses';

export enum DetectionModelsActionTypes {
  RETRIEVE_DETECTION_MODELS_PERFORMANCE = '@@detectionModels/RETRIEVE_DETECTION_MODELS_PERFORMANCE',
  RETRIEVE_DETECTION_MODELS_PERFORMANCE_SUCCESS = '@@detectionModels/RETRIEVE_DETECTION_MODELS_PERFORMANCE_SUCCESS',
  RETRIEVE_SCENARIO_CONFIGS = '@@detectionModels/RETRIEVE_SCENARIO_CONFIGS',
  RETRIEVE_SCENARIO_CONFIGS_SUCCESS = '@@detectionModels/RETRIEVE_SCENARIO_CONFIGS_SUCCESS',

  RETRIEVE_DETECTION_MODEL_VALIDATION = '@@detectionModels/RETRIEVE_DETECTION_MODEL_VALIDATION',
  RETRIEVE_DETECTION_MODEL_VALIDATION_SUCCESS = '@@detectionModels/RETRIEVE_DETECTION_MODEL_VALIDATION_SUCCESS',
  DELETE_VALIDATION_MODEL = '@@detectionModels/DELETE_VALIDATION_MODEL',
  DELETE_VALIDATION_MODEL_SUCCESS = '@@detectionModels/DELETE_VALIDATION_MODEL_SUCCESS',
  VALIDATE_DETECTION_MODEL = '@@detectionModels/VALIDATE_DETECTION_MODEL',
  VALIDATE_DETECTION_MODEL_SUCCESS = '@@detectionModels/VALIDATE_DETECTION_MODEL_SUCCESS',
  REVALIDATE_DETECTION_MODEL = '@@detectionModels/REVALIDATE_DETECTION_MODEL',
  REVALIDATE_DETECTION_MODEL_SUCCESS = '@@detectionModels/REVALIDATE_DETECTION_MODEL_SUCCESS',
  RETRIEVE_DETECTION_MODEL_VALIDATION_ALERTS = '@@detectionModels/RETRIEVE_DETECTION_MODEL_VALIDATION_ALERTS',
  RETRIEVE_DETECTION_MODEL_VALIDATION_ALERTS_SUCCESS = '@@detectionModels/RETRIEVE_DETECTION_MODEL_VALIDATION_ALERTS_SUCCESS',

  RETRIEVE_DETECTION_MODEL_TEMPLATES = '@@detectionModels/RETRIEVE_DETECTION_MODEL_TEMPLATES',
  RETRIEVE_DETECTION_MODEL_TEMPLATES_SUCCESS = '@@detectionModels/RETRIEVE_DETECTION_MODEL_TEMPLATES_SUCCESS',

  RETRIEVE_DETECTION_MODEL_VALIDATION_METRICS = '@@detectionModels/RETRIEVE_DETECTION_MODEL_VALIDATION_METRICS',
  RETRIEVE_DETECTION_MODEL_VALIDATION_METRICS_SUCCESS = '@@detectionModels/RETRIEVE_DETECTION_MODEL_VALIDATION_METRICS_SUCCESS',

  SET_EDITING_ADVANCED_DETECTION_MODEL = '@@detectionModels/SET_EDITING_ADVANCED_DETECTION_MODEL',
  SET_EDITING_SIMPLE_DETECTION_MODEL = '@@detectionModels/SET_EDITING_SIMPLE_DETECTION_MODEL',
  RESET_EDITING_DETECTION_MODELS = '@@detectionModels/RESET_EDITING_DETECTION_MODELS',
  RESET_MODEL_VALIDATION = '@@detectionModels/RESET_MODEL_VALIDATION',
}

export interface ModelsState {
  detectionModelsPerformance: DetectionModelsPerformance;
  editingSimpleDetectionModel: EditingSimpleDetectionModel;
  editingAdvancedDetectionModel: EditingAdvancedDetectionModel;
  scenarioConfigs: ScenarioConfigs;
  detectionModelValidation: DetectionModelValidation;
  detectionModelValidationMetrics: DetectionModelMetricsResponse;
  validationAlerts: PaginatedAlerts;
  validateModelPayload: ValidateModelPayload;
  detectionModelTemplates: DetectionModelTemplatesResponse;
}

export interface QueryTreeFiltersModel {
  raw_sql: string;
  inclusion_tags: number[];
  inclusion_tag_names?: string[];
  exclusion_tags: number[];
  exclusion_tag_names?: string[];
  query_tree: ImmutableTree;
  where_clause?: any;
  aggregate_query_tree: any;
  grouping?: string[];
}

export interface TimeWindow {
  key: string;
  value: string;
  text: string;
  minutes: number;
  defaultstride: string;
}

export interface ScenarioParameters {
  '$numeric_comparison-comparator'?: string;
  '$numeric_comparison-type'?: string;
  '$numeric_comparison-value'?: string;
  $time_window?: string;
  $window_stride?: string;
  count_field?: string;
  group_type?: string;
  additional_fields?: {
    [key: string]: string;
  };
  dynamic_column_definition_id?: number;
  alert_group_by_policy?: string;
  txn_events?: string;
  // ScenarioBlacklist Widget
  blacklist_id?: number;
  // ScenarioPerBlacklistEditor Widget
  match_conditions?: any;
  match_conditions_validated?: boolean;
  // Rule Combination
  component_rule_ids?: any;
  // Flag single object
  flag_single_object?: boolean;
  // Consortium parameters
  // The param is used solely for validation
  consortium_dynamic_condition_valid?: boolean;
  // Check Fraud Rule: The param is used solely for validation
  check_fraud_content_valid?: boolean;
  // Only used in watchlist scenario
  watchlist?: WatchlistParameters;

  ach_fraud_content_valid?: boolean;
  // Only used in ACH Risk Score scenario
  ach_risk_score_content_valid?: boolean;
  // Only used in ACH Consortium scenario
  ach_consortium_content_valid?: boolean;
  // Only used in ACH Mismatched scenario
  ach_mismatched_content_valid?: boolean;
  // Only used in ACH Duplicate scenario
  ach_duplicate_content_valid?: boolean;

  [key: string]: any | undefined;
}

export const USER_PII_MAP = {
  first_name: 'First name',
  last_name: 'Last name',
  year_of_birth: 'Year of birth',
  registered_state: 'State',
  registered_country: 'Country',
};
export const BUSINESS_PII_MAP = {
  business_name: 'Business name',
  registered_state: 'Registered state',
  registered_country: 'Registered country',
};

export interface Metric {
  label: string;
  value: string | number;
  color?: string | 'auto';
}

export interface PaginationGridMetric extends Metric {
  id: number;
  tooltip?: Metric[];
}

export interface DetectionModelsPerformance {
  series: {
    name: string;
    type: 'line' | 'column';
    data: number[][]; // [x, y]
  }[];
  metrics: Metric[];
}

export interface AlertsHistogramPayload {
  start_time?: string;
  end_time?: string;
}

export interface ModelValidationPayload {
  start_time: string;
  end_time: string;
  execution_frequency: string;
}

export interface ScenarioPayload {
  name: string;
  embeddedFilters: EmbeddedFilters;
  parameters: ScenarioParameters;
  filters?: EmbeddedFilters;
}

export interface EmbeddedFilters {
  [scenarioParameter: string]: QueryTreeFiltersModel;
}

// TODO define
interface ScenarioFilterOptions {
  [key: string]: any;
}

// TODO define
interface ScenarioParameterOptions {
  alert_group_by_policy?: {
    default_value?: string;
    field_type: 'dropdown';
    key_values: {
      key: string;
      value: string;
      text: string;
    }[];
  };
}

// TODO define
interface ScenarioDynamicValues {
  [key: string]: any;
}

export type ScenarioCategory =
  | 'STRUCTURING'
  | 'ANOMALY'
  | 'CARDINALITY'
  | 'STATISTICS'
  | 'BLACKLIST' // NOTE: for legacy support
  | 'LIST'
  | 'CHECK_FRAUD'
  | 'ACH_FRAUD'
  | 'OTHER';

export type CategoryConfig = {
  label: string;
  showNewLabel?: boolean;
};
export type ScenarioCategoryConfig = {
  [key in ScenarioCategory]: CategoryConfig;
};
type ScenarioTypes = 'WINDOWED' | 'ALL_TIME' | 'NON_WINDOWED' | null;
type ScenarioWidgets = string;
type WindowTypes = 'Alert' | 'TxnInstrument';
type ScenarioApplicableTypes = 'events' | 'entities';
export interface ScenarioConfig {
  allow_run_on_historical?: boolean;
  applicable_types: ScenarioApplicableTypes[];
  content: string;
  default_tags: string[];
  display_name: string;
  enabled: boolean;
  name: string;
  filter_options?: ScenarioFilterOptions;
  parameter_options?: ScenarioParameterOptions;
  dynamic_values?: ScenarioDynamicValues;
  widgets?: ScenarioWidgets[];
  scenario_type: ScenarioTypes;
  window_type?: WindowTypes;
  disable_filters?: boolean;
  stride_with_no_window?: boolean;
  category?: ScenarioCategory;
  flairs?: string[];
}
export interface ScenarioPreviewContent {
  name: string;
  displayName: string;
  category: string;
  description: string;
  functionality: string;
}
export interface ScenarioConfigs {
  [scenarioName: string]: ScenarioConfig;
}

// Simple model
export type SimpleModelStep =
  | 'SCENARIO_PICKER'
  | 'SCENARIO_BUILDER'
  | 'METADATA_PICKER'
  | 'VALIDATION_PICKER';

export type AdvancedModelStep =
  | 'TEMPLATE_PICKER'
  | 'ADVANCED_BUILDER'
  | 'METADATA_PICKER'
  | 'VALIDATION_PICKER';

export interface StepPayload {
  label: string;
  nextStep: SimpleModelStep | null; // Null = Last step

  canContinue: (
    state: EditingSimpleDetectionModel,
    scenarioConfigs: ScenarioConfigs,
    orgScenarioConfigs: OrgScenarioConfig,
  ) => boolean;
}

// Simple and advanced flows inherit their state from this interface
export interface DetectionModelMetadata {
  title: string;
  description: string;
  customer_support_involved: boolean;
  parentRuleId?: number;
  tags: number[];
  queue: number | null;
  runs_on_org_id: number | null;
  visible_to_child_org: boolean;
  generates_alerts?: boolean;
}
export interface EditingDetectionModel {
  metadata: DetectionModelMetadata | null;
  validationPayload: ModelValidationPayload | null;
  // For revalidation
  ruleId?: number;
  // Used for editing/duplicating a model, we are redirected to this step
  initialStep?: SimpleModelStep | AdvancedModelStep;
}

export interface EditingSimpleDetectionModel extends EditingDetectionModel {
  objectToFlag: string | null;
  scenario: ScenarioPayload | null;
  specification?: RuleSpecification;
  initialStep?: SimpleModelStep;
}

export interface EditingAdvancedDetectionModel extends EditingDetectionModel {
  irSchema: IRSchema | null;
  template: DetectionModelTemplate | null;
  initialStep?: AdvancedModelStep;
}

export interface DeleteValidationModelPayload {
  id: number;
  showToast?: boolean;
}

export type ValidationResultStatus =
  | 'PENDING'
  | 'RUNNING'
  | 'COMPLETE'
  | 'GENERATING_VALIDATION_ALERTS'
  | 'QUERY_TIMEOUT'
  | 'FAILED'
  | 'SPOOLED'
  | '';

export interface RuleValidationWindow {
  created_at: string;
  id: number;
  org_name: string;
  processed_at: string;
  rule_id: number;
  rule_validation_id: number;
  unit_name: string;
  window_end: string;
  window_start: string;
}

export interface RuleGraphMatches {
  field: { type: string; value: string }[];
  entity_ids: number[];
  entity_external_ids: string[];
}

export type PossibleEvaluatedEventStatuses = 'PASS' | 'FAIL' | 'ERROR';

export interface EvaluatedEvent {
  error_message?: string;
  external_id: string;
  event_time: string;
  status: PossibleEvaluatedEventStatuses;
}

export type RealTimeMonitoringEvaluatedEventsResults = Record<
  string,
  EvaluatedEvent
>;

export interface RealTimeMonitoringEvaluatedEvent {
  internalId: string;
  errorMessage: string;
  externalId: string;
  eventTime: string;
  status: PossibleEvaluatedEventStatuses;
}

export interface DetectionModelValidation {
  id: number;
  tags: number[];
  title: string;
  description: string;
  queue: number | null;
  runs_on_org_id: number | null;
  customer_support_involved: boolean | null;
  execution_frequency: string;
  created_at: string;
  completed_at: string | null;
  deploy_end_date: string;
  deploy_start_date: string;
  created_by_readable: string;
  status: ValidationResultStatus;
  progress:
    | {
        cur_processing_window: number;
        num_windows: number;
      }
    | Record<string, never>;
  validation_result: {
    hit_key_field: string;
    hit_value_field: string;
    metrics?: PaginationGridMetric[];
    rule_validation_histograms: {
      in: {
        data: number[][];
        name: string;
      }[];
      out: {
        data: number[][];
        name: string;
      }[];
    };
    rule_validation_id: number;
    rule_validation_results: {
      alert_timestamp: string;
      column_name: string;
      column_type: null | string;
      column_value: string;
      created_at: string;
      hit_id: string;
      id: number;
      org_name: string;
      unit_name: string;
    }[];
    rule_validation_windows: RuleValidationWindow[];
    status: ValidationResultStatus;
    validation_histogram: EventHistogram | null;
  };
  validation_id: number | null;
  exec_range_start: string;
  exec_range_end: string;
  override_qbc: QueryBuilderConfig | null;
  content: {
    scenario: {
      filters: {
        [key: string]: RuleFiltersModel;
      };
      name: string;
      parameters: ScenarioParameters;
    };
    specification?: RuleSpecification;
  };
  template: DetectionModelTemplate | null;
  visible_to_child_org: boolean | null;
  friendly_errors: string[] | null;
  results: {
    aggregate_list?: RuleGraphMatches[];
    rtm_evaluated_events?: RealTimeMonitoringEvaluatedEventsResults;
  } | null;
  scenario: string;
  scenario_type: string;
  options?: {
    activation_window_count_limit?: number;
    first_window_end_recency_in_days?: number;
  } | null;
}

export interface DetectionModelContent {
  scenario: {
    filters: EmbeddedFilters;
    name: string;
    parameters: ScenarioParameters;
  };
  specification?: RuleSpecification;
}

export interface ActivateDetectionModelPayload {
  id: number;
  // TODO remove this and update the new endpoint to stop using the old schema
  alertQueueId?: number | null;
  metadata: RuleMetadata;
}

export interface ContentScenario {
  scenario_name: string;
  scenario_type: ScenarioTypes;
  parameters: ScenarioParameters;
}

export interface DetectionModelContents {
  scenario: ContentScenario;
  execution_frequency: string;
  specification: RuleSpecification;
}

export interface ActivateSynchronousDetectionModelPayload {
  alertQueueId?: number | null;
  alert_priority?: number | null;
  contents: DetectionModelContents;
  generates_alerts: boolean;
  id?: number;
  is_synchronous: boolean;
  metadata: RuleMetadata;
  tags?: number[];
}

export interface BulkActivateDetectionModelPayload {
  rule_ids: number[];
  alert_queue_id?: number | null;
  metadata: BulkRuleDeployMetaData;
}

export interface ValidateModelPayload {
  contents: {
    scenario: {
      scenario_name: string;
      parameters: {
        $time_window: string;
        $window_stride: string;
        alert_group_by_policy?: string;
        count_field?: string;
        group_type?: string;
        txn_events?: string;
        flag_single_object?: boolean;
        [param: string]: any;
      };
      filters: RuleScenarioFilters;
    };
    execution_frequency: string;

    // TODO remove this, it was intended for old filters
    query_tree: Record<string, never>;
    inclusion_tags: number[];
    inclusion_tag_names: string[];
    exclusion_tags: number[];
    exclusion_tag_names: string[];
    specification?: RuleSpecification;
  };

  // Validation time range
  exec_range_start: string;
  exec_range_end: string;

  // Add metadata
  tags: number[];
  runs_on_org_id?: number | null;
  metadata: RuleMetadata;
  template_id?: number;
  visible_to_child_org?: boolean;
  is_synchronous?: boolean;
  generates_alerts?: boolean;
}

export interface RetrieveValidationMetricsPayload {
  id: number;
  start_time?: string;
  end_time?: string;
}

export interface DetectionModelMetricsResponse {
  id: number;
  metrics: Metric[];
  start_time?: string;
  end_time?: string;
}

export interface ValidateModelResponse {
  id: number;
  rule_id: number;
}

export interface DetectionModel extends FullRuleResponse {
  readonly parent_org_name?: string;
}

export type ValidationAlertsPayload = RetrieveValidationRuleAlertsPayload;

export type DetectionModelTemplateType =
  | 'ABNORMAL_VOLUME'
  | 'HISTORICAL_DEVIATION'
  | 'SMURFING'
  | 'LAYERING'
  | '';
export interface DetectionModelTemplate {
  id: number;
  title: string;
  display_name: string | null;
  description: string;
  content: IRSchema;
  type: DetectionModelTemplateType;
  industries: string[];
  popularity_score: number;
}

export interface DetectionModelTemplatesResponse {
  templates: DetectionModelTemplate[];
  count: number;
}

export interface DetectionModelTemplatesPayload extends PaginationPayload {
  phrase?: string;
  type?: DetectionModelTemplateType;
  industries?: string[];
  primary_object: DmObjectToFlag;
}

export type QueryBuilderConfigField =
  | QueryBuilderConfigStruct
  | QueryBuilderConfigValue;

interface QueryBuilderConfigTextValue {
  type: 'text';
  label: string;
}

interface QueryBuilderConfigNumberValue {
  type: 'number';
  label: string;
}

interface QueryBuilderConfigDateValue {
  type: 'date';
  label: string;
}

interface QueryBuilderConfigDatetimeValue {
  type: 'datetime';
  label: string;
}

interface QueryBuilderConfigSelectValue {
  type: 'select';
  label: string;
  fieldSettings: {
    listValues: Record<string, string>;
  };
}

interface QueryBuilderConfigMultiselectValue {
  type: 'multiselect';
  label: string;
  fieldSettings: {
    listValues: Record<string, string>;
  };
}

type QueryBuilderConfigValue = (
  | QueryBuilderConfigTextValue
  | QueryBuilderConfigNumberValue
  | QueryBuilderConfigDateValue
  | QueryBuilderConfigDatetimeValue
  | QueryBuilderConfigSelectValue
  | QueryBuilderConfigMultiselectValue
) & {
  cardinality?: 'one' | 'many';
};

export interface QueryBuilderConfigStruct {
  type: '!struct';
  label: string;
  subfields: Record<string, QueryBuilderConfigField>;
  cardinality?: 'one' | 'many';
  ambiguous?: boolean;
}

export interface DmbQueryBuilderConfig {
  fields: Record<string, QueryBuilderConfigStruct>;
}

export interface LimitedEventSubTypeOptionsType {
  eventSubTypesOverLimit: Set<string>;
  options: U21SelectOptionProps[];
  isEverySubTypeOverLimit: boolean;
}
