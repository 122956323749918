import { RawDraftContentState } from 'draft-js';

// Types
import {
  AssociatedAlert,
  AssociatedCase,
  AssociatedEntitiesResponse,
  AssociatedInstrumentsResponse,
  AssociatedSAR,
  FullAlertResponse,
  ShortAlertResponse,
} from 'app/modules/alerts/types';
import { ShortEntityResponse } from 'app/modules/entities/types';
import { ShortTxnResponse } from 'app/modules/transactions/types';
import { ShortTxnInstrumentResponse } from 'app/modules/txnInstruments/types';
import { ShortAuditTrailResponse } from 'app/shared/auditTrail/types';
import { EventHistogram } from 'app/shared/types/histogram';

// Models
import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';
import { AlertSource } from 'app/modules/alerts/types/alert';
import { EntityType } from 'app/modules/entities/models';
import { CustomDataFilters } from 'app/modules/filtersOld/models';
import { InvestigationChecklistSummary } from 'app/modules/investigationChecklist/models';
import { QueueAccessType, QueueType } from 'app/modules/queues/models';
import { SummaryRuleResponse } from 'app/modules/rules/types';
import { ShortTeamResponse } from 'app/modules/teams/responses';
import { GoogleDriveDoc } from 'app/modules/uploads/models';
import { CustomData, PaginationPayload } from 'app/shared/models';

export enum AlertsActionTypes {
  RETRIEVE_ALERTS = '@@alerts/RETRIEVE_ALERTS',
  RETRIEVE_ALERTS_SUCCESS = '@@alerts/RETRIEVE_ALERTS_SUCCESS',

  // Used from react-query to update the state. See: useFetchAlertsAlertCount.ts
  RETRIEVE_ALERTS_ALERT_COUNT_SUCCESS = '@@alerts/RETRIEVE_ALERTS_ALERT_COUNT_SUCCESS',

  // Default behavior for RETRIEVE_ALERTS_SUCCESS is to add to end of alerts (bc AlertList infinite scroll)
  APPEND_TO_TOP_OF_ALERTS = '@@alerts/APPEND_TO_TOP_OF_ALERTS',

  RETRIEVE_MY_ALERTS_STATS = '@@alerts/RETRIEVE_RETRIEVE_MY_ALERTS_STATS',
  RETRIEVE_MY_ALERTS_STATS_SUCCESS = '@@alerts/RETRIEVE_MY_ALERTS_STATS_SUCCESS',

  ASSOCIATE_ALERTS_WITH_CASES = '@@alerts/ASSOCIATE_ALERTS_WITH_CASES',
  ASSOCIATE_ALERTS_WITH_CASES_SUCCESS = '@@alerts/ASSOCIATE_ALERTS_WITH_CASES_SUCCESS',

  RETRIEVE_ALERT = '@@alerts/RETRIEVE_ALERT',
  RETRIEVE_ALERT_SUCCESS = '@@alerts/RETRIEVE_ALERT_SUCCESS',

  RETRIEVE_ALERT_DETAILS = '@@alerts/RETRIEVE_ALERT_DETAILS',
  RETRIEVE_ALERT_DETAILS_SUCCESS = '@@alerts/RETRIEVE_ALERT_DETAILS_SUCCESS',

  CLEAR_ALERT_ENTITIES = '@@alerts/CLEAR_ALERT_ENTITIES',

  CLEAR_ALERTS_LIST = '@@alerts/CLEAR_ALERTS_LIST',

  RETRIEVE_ALERT_TRANSACTIONS = '@@alerts/RETRIEVE_ALERT_TRANSACTIONS',
  RETRIEVE_ALERT_TRANSACTIONS_SUCCESS = '@@alerts/RETRIEVE_ALERT_TRANSACTIONS_SUCCESS',

  RETRIEVE_ALERT_AUDIT_TRAIL = '@@alerts/RETRIEVE_ALERT_AUDIT_TRAIL',
  RETRIEVE_ALERT_AUDIT_TRAIL_SUCCESS = '@@alerts/RETRIEVE_ALERT_AUDIT_TRAIL_SUCCESS',

  RETRIEVE_ALERT_ENTITIES = '@@alerts/RETRIEVE_ALERT_ENTITIES',
  RETRIEVE_ALERT_ENTITIES_SUCCESS = '@@alerts/RETRIEVE_ALERT_ENTITIES_SUCCESS',

  RETRIEVE_ALERT_INSTRUMENTS = '@@alerts/RETRIEVE_ALERT_INSTRUMENTS',
  RETRIEVE_ALERT_INSTRUMENTS_SUCCESS = '@@alerts/RETRIEVE_ALERT_INSTRUMENTS_SUCCESS',

  CHANGE_ALERT_COMPONENT_STATUS = '@@alerts/CHANGE_ALERT_COMPONENT_STATUS',
  CHANGE_ALERT_COMPONENT_STATUS_SUCCESS = '@@alerts/CHANGE_ALERT_COMPONENT_STATUS_SUCCESS',

  REASSIGN_ALERTS = '@@alerts/REASSIGN_ALERTS',
  REASSIGN_ALERTS_SUCCESS = '@@alerts/REASSIGN_ALERTS_SUCCESS',

  REQUEUE_ALERTS = '@@alerts/REQUEUE_ALERTS',
  REQUEUE_ALERTS_SUCCESS = '@@alerts/REQUEUE_ALERTS_SUCCESS',

  CREATE_ALERT = '@@alerts/CREATE_ALERT',
  CREATE_ALERT_SUCCESS = '@@alerts/CREATE_ALERT_SUCCESS',

  EDIT_ALERT = '@@alerts/EDIT_ALERT',
  EDIT_ALERT_SUCCESS = '@@alerts/EDIT_ALERT_SUCCESS',

  ADD_DOCUMENTS_SUCCESS = '@@alerts/ADD_DOCUMENTS_SUCCESS',
  DELETE_DOCUMENT_SUCCESS = '@@alerts/DELETE_DOCUMENT_SUCCESS',
  EDIT_DOCUMENT_SUCCESS = '@@alerts/EDIT_DOCUMENT_SUCCESS',

  TRIGGER_ALERT_ACTION = '@@alerts/TRIGGER_ALERT_ACTION',
  TRIGGER_ALERT_ACTION_SUCCESS = '@@alerts/TRIGGER_ALERT_ACTION_SUCCESS',

  RETRIEVE_ALERT_QUEUES = '@@alerts/RETRIEVE_ALERT_QUEUES',
  RETRIEVE_ALERT_QUEUES_SUCCESS = '@@alerts/RETRIEVE_ALERT_QUEUES_SUCCESS',

  RETRIEVE_ALL_ALERT_QUEUES = '@@alerts/RETRIEVE_ALL_ALERT_QUEUES',
  RETRIEVE_ALL_ALERT_QUEUES_SUCCESS = '@@alerts/RETRIEVE_ALL_ALERT_QUEUES_SUCCESS',

  RETRIEVE_UNASSIGNED_ALERTS = '@@alerts/RETRIEVE_UNASSIGNED_ALERTS',
  RETRIEVE_UNASSIGNED_ALERTS_SUCCESS = '@@alerts/RETRIEVE_UNASSIGNED_ALERTS_SUCCESS',

  RETRIEVE_ALERT_HISTOGRAM = '@@alerts/RETRIEVE_ALERT_HISTOGRAM',
  RETRIEVE_ALERT_HISTOGRAM_SUCCESS = '@@alerts/RETRIEVE_ALERT_HISTOGRAM_SUCCESS',

  RETRIEVE_ALERT_ACTION_EVENTS = '@@alerts/RETRIEVE_ALERT_ACTION_EVENTS',
  RETRIEVE_ALERT_ACTION_EVENTS_SUCCESS = '@@alerts/RETRIEVE_ALERT_ACTION_EVENTS_SUCCESS',

  UPDATE_ALERT_CACHE = '@@alerts/UPDATE_ALERT_CACHE',
}

export interface RetrieveAlertQueuesListResponse {
  queues: AlertQueue[];
  count: number;
}

export interface CreateAlertPayload {
  id: number;
  title: string;
  description?: string;
  entities?: string[];
  transactions?: string[];
  action_events?: string[];
  tags?: number[];
  queue?: number;
  custom_data?: CustomData;
}

export interface AlertSummary extends ShortAlertResponse {}

export interface ChangeAlertStatusPayload {
  alertId: number;
  entities?: string[];
  events?: (number | string)[];
  status?: AlertResolutionTypes | EntityResolutionTypes;
  txn_instruments?: (number | string)[];
  action_trigger_id?: number;
}
export interface AssociateAlertsWithCasesApi {
  case_ids: number[];
  alert_ids: number[];
}
// separate model, api does not use source_alert_id
export interface AssociateAlertsWithCasesPayload
  extends AssociateAlertsWithCasesApi {
  source_alert_id: number;
  alertsPageChanged?: boolean;
}

export interface ReassignAlertsPayload {
  agent_id: number;
  alert_ids: number[];
  filters?: GetAlertsPayload;
}

export interface RequeueAlertsPayload {
  alert_queue_id: number;
  alert_ids: number[];
  filters?: GetAlertsPayload;
}

export interface RetrieveAlertTxnsPayload extends PaginationPayload {
  hash_key: string;
  useCacheEndpoint: boolean;
}

export interface RetrieveAlertEntitiesPayload extends PaginationPayload {
  alertId: string;
  entity_type?: EntityType;
}

export interface RetrieveAlertHistogramPayload {
  // If we want to get the histogram relating to the given alert
  alert_id?: number;
  // If we want to get the histogram relating to a particular entity
  entity_id?: number | string;
}

export type EntityResolutionTypes =
  | 'FALSE_POSITIVE'
  | 'TRUE_POSITIVE'
  | 'UNRESOLVED'
  | 'WHITELIST_ENTITY';

type AlertResolutionTypes =
  | 'FALSE_POSITIVE'
  | 'TRUE_POSITIVE'
  | 'UNRESOLVED'
  | 'CLOSED';

export interface AlertActionEventResponse {
  count: number | null;
  events: ShortActionEventResponse[];
}

export type AlertDetails = FullAlertResponse & {
  events?: ShortTxnResponse[];
  instruments?: ShortTxnInstrumentResponse[];
  paginatedInstruments?: AssociatedInstrumentsResponse;
  entities?: ShortEntityResponse[];
  paginatedEntities?: AssociatedEntitiesResponse;
  audit_trail?: ShortAuditTrailResponse[];
  associatedAlerts?: ShortAlertResponse[];
  action_events?: AlertActionEventResponse;
};

export interface TableModel {
  data: Record<string, string>[];
  data_count: number;
  key: string;
  statistics: any;
  table_name: string;
  table_type: 'events';
  hitId: number;
  custom_data?: {
    key: string;
    type: string;
    label: string;
  }[];
}

export interface AlertsState {
  alerts: AlertSummary[];
  alert: AlertDetails;

  alertsCount: number;
  alertsEntitiesCount: number;
  myAlertsStats: GetMyAlertsStatsResponse;
  alertsLastCount: number;

  alertQueues: AlertQueue[];

  sidebarObject?: AlertSummary;

  histogram: EventHistogram;
}

export interface PaginatedAlertsPayloadModel {
  alerts: AlertSummary[];
  count: number;
}

export type AlertStatus =
  | 'OPEN'
  | 'CLOSED'
  | 'IN_REVIEW'
  | 'OPEN_SHADOW'
  | 'RULE_VALIDATION';
export type AlertSources = `${AlertSource}`;

export interface GetMyAlertsStatsResponse {
  open_alerts_count: number;
  alerts_completed_last_24_hrs_count: number;
}

export interface AlertFiltersPayload {
  agent_ids?: number[];
  alert_ids?: number[];
  alert_queue_ids?: number[];
  alert_types?: string[];
  assigned_to_id?: number;
  created_end_date?: string;
  created_start_date?: string;
  custom_data_filters?: CustomDataFilters;
  disposition?: string;
  disposition_end_date?: string;
  disposition_start_date?: string;
  entity_ids?: (number | string)[];
  entity_external_ids?: string[];
  max_alert_score?: number;
  maximum_amount?: number;
  min_alert_score?: number;
  minimum_amount?: number;
  my_queues_only?: boolean;
  phrase?: string;
  queue_ids?: number[];
  rule_ids?: number[];
  sources?: string[];
  statuses?: string[];
  subdisposition_ids?: number[];
  subdisposition_option_ids?: number[];
  is_not_subdisposition_option_ids?: number[];
  tag_ids?: number[];
  is_not_tag_ids?: number[];
  tags?: string[];
  team_ids?: number[];
}

export interface GetAlertsPayload
  extends AlertFiltersPayload,
    PaginationPayload {
  // Determines whether new alerts replace or append to existing ones
  appendToExisting?: boolean;
}

export interface GetAlertsListResponse {
  alerts: AlertSummary[];
  count: number;
  // Optional field used by reducer to determine whether
  // to append to existing alerts or replace
  append?: boolean;
}

export interface AlertEditPayload {
  id: number;
  notes?: RawDraftContentState;
  documents?: GoogleDriveDoc[];
  case_ids?: number[];
  tags?: number[];
  is_work_started?: boolean;
  custom_data?: CustomData;
  narrative?: string;
}

export interface DeleteDocumentPayload {
  alertId: number;
  docId: number;
}

export interface AddDocumentsPayload {
  alertId: number;
  documents: GoogleDriveDoc[];
}

export interface AlertQueueSummary {
  /* This type matches the BE type found
  here:
    patronus/server/alert_queues/formatters.py
    AlertQueueSummaryResponse
  */
  id: number;
  title: string;
}

export interface AlertQueue extends AlertQueueSummary {
  /* This type matches the BE type found
  here:
    patronus/server/alert_queues/formatters.py
    AlertQueueDetailResponse
  */
  description: string;
  org_id: number;
  unit_id: number;
  org_default_queue: boolean;
  custom_checklist_id: number | null;
  custom_checklist: InvestigationChecklistSummary | null;
  teams: ShortTeamResponse[];
  rules: SummaryRuleResponse[];
  open_articles: number;
  workflows: AlertQueueWorkflows[];
  sort_by: string;
  created_at: string | null;
  // is optional since there isn't really a `creator` for the default queue
  created_by_full_name: string;
  unassigned_count: number;
  high_threshold: number | null;
  low_threshold: number | null;
  type: QueueType.ALERT_QUEUE;
  access_type: QueueAccessType;
}

export interface AlertQueueWorkflows {
  description: string;
  id: number;
  is_active: boolean;
  is_deleted: boolean;
  text: string;
}

export enum QueueSortTypes {
  HIGHEST_TRANSACTION_VALUE = 'HIGHEST_TRANSACTION_VALUE',
  OLDEST_CREATION_DATE = 'OLDEST_CREATION_DATE',
  HIGH_ALERT_SCORE = 'HIGH_ALERT_SCORE',
}

export enum QueueSubtypes {
  TM = 'TM',
  CAR = 'CAR',
  CHAINALYSIS = 'CHAINALYSIS',
  DARK_WEB = 'DARK_WEB',
}

export enum AlertPages {
  MY_ALERTS = 'MY_ALERTS',
  ADMIN = 'ADMIN',
  QUEUE_DETAILS = 'QUEUE_DETAILS',
  QUEUED_ALERTS = 'QUEUED_ALERTS',
  QUALITY_CYCLE_ALERTS = 'QUALITY_CYCLE_ALERTS',
}

export interface RetrieveUnassignedAlertsPayload {
  alert_queue_id: number;
  filters?: AlertFiltersPayload;
}

export type AssociatedObject = AssociatedAlert | AssociatedCase | AssociatedSAR;

export type AssociatedObjectType = 'alert' | 'case' | 'sar';

export interface AlertScore {
  score: number;
  risk: boolean;
}
