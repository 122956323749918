import {
  DataSettingFieldType,
  FormattedData,
  OrgDataSettingsConfig,
} from 'app/modules/dataSettings/responses';
import { DataSettingDataDisplay } from 'app/modules/dataSettings/shared/DataSettingDataDisplay';
import { DataSettingDataLabel } from 'app/modules/dataSettings/shared/DataSettingDataLabel';
import { getFormattedValue } from 'app/modules/dataSettings/utils';
import {
  U21DataDisplay,
  U21InfoItem,
  U21JsonViewer,
  U21NoValue,
  U21Spacer,
} from 'app/shared/u21-ui/components';
import { useMemo } from 'react';
import styled from 'styled-components';

type CardFieldWithLabel = {
  key: string;
  dataSetting?: OrgDataSettingsConfig;
  data: FormattedData;
  isEmpty: boolean;
};

interface MatchCardProps {
  // The fields to render in the section (ie 'Unit21 ID')
  fields: CardFieldWithLabel[];
  isEmptyFieldShown: boolean;
}

export const MatchCardSection = ({
  fields,
  isEmptyFieldShown,
}: MatchCardProps) => {
  const fieldsToDisplay = useMemo(() => {
    return fields.map((item: CardFieldWithLabel) => {
      const { key, dataSetting, data, isEmpty } = item;

      const dsData = dataSetting ? data[dataSetting.id] : undefined;

      if (isEmptyFieldShown || !isEmpty) {
        const value = dsData ? getFormattedValue(dsData) : undefined;

        const isObjectArray =
          dataSetting?.data_type === DataSettingFieldType.LIST &&
          value !== undefined &&
          value.some((i) => typeof i === 'object' && i !== null);

        return (
          <U21InfoItem
            key={key}
            label={
              dataSetting ? (
                <StyledDataSettingDataLabel
                  dataSetting={dataSetting}
                  variant="caption"
                  color="text.secondary"
                />
              ) : (
                <U21NoValue />
              )
            }
            width={150}
          >
            {data &&
              (isObjectArray ? (
                // Needed to override LIST display for array of objects
                <U21DataDisplay
                  variant="LIST"
                  value={value}
                  componentProps={{
                    limit: 1,
                    displayFunc: (val: object) => (
                      <U21JsonViewer data={val} isDefaultCollapsed />
                    ),
                  }}
                />
              ) : (
                <DataSettingDataDisplay
                  dataSetting={dataSetting}
                  formattedData={data}
                />
              ))}
          </U21InfoItem>
        );
      }
      return null;
    });
  }, [isEmptyFieldShown, fields]);

  return <U21Spacer>{fieldsToDisplay}</U21Spacer>;
};

const StyledDataSettingDataLabel = styled(DataSettingDataLabel)`
  margin-top: 2px;
`;
