export enum WatchlistFeedTypes {
  PEP = 'PEP',
  SANCTIONS = 'Sanctions',
  NEWS = 'News',
}

export enum WatchlistProviders {
  UNIT22 = 'Unit22', // Dummy provider so we don't accidentally trigger any screenings that cost money
  LEXISNEXIS = 'LexisNexis',
}
